<template>
	<BInputGroup class="mt-2">
		<BFormInput
			:value="value"
			:class="['search-input', { 'with-prepend': searchPrepend }]"
			:placeholder="$t('searchPlaceholder')"
			type="search"
			maxlength="30"
			:data-test-automation-id="getAutomationId('searchInput')"
			:data-tracking-id="getAutomationId('searchInput')"
			@focus="setSearchFocus(true)"
			@blur="setSearchFocus(false)"
			@input="$emit('input', $event)"
			@update="$emit('update', $event)"
			@keyup="$emit('keyup', $event)"
			@keydown="$emit('keydown', $event)"
		></BFormInput>
		<template v-if="searchPrepend" #prepend>
			<div class="prepend d-flex align-items-center pl-2" :class="{ focus: searchHasFocus }">
				<FontAwesomeIcon :icon="['fal', 'search']" />
			</div>
		</template>
		<template v-if="searchButton" #append>
			<BButton
				class="search-button"
				variant="primary"
				:data-test-automation-id="getAutomationId('searchButton')"
				:data-tracking-id="getAutomationId('searchButton')"
				@click="$emit('click', $event)"
				@keyup="$emit('keyup', $event)"
			>
				<FontAwesomeIcon class="mr-2" :icon="['fal', 'search']"></FontAwesomeIcon>
				<span>{{ $t('searchLabel') }}</span>
			</BButton>
		</template>
	</BInputGroup>
</template>

<script>
import IdMixin from '@/mixins/id';
import { BInputGroup, BFormInput, BButton } from 'bootstrap-vue';
export default {
	name: 'SearchInput',
	components: {
		BInputGroup,
		BFormInput,
		BButton
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: String,
			required: true
		},
		searchButton: {
			type: Boolean,
			default: true
		},
		searchPrepend: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			searchHasFocus: false
		};
	},
	methods: {
		setSearchFocus(focused) {
			this.searchHasFocus = focused;
		}
	}
};
</script>

<style lang="scss" scoped>
.search-input {
	background-color: $white;
	padding-left: 8px;
	height: 42px;
	&.with-prepend {
		border-left: none;
	}
}
.prepend {
	background-color: $white;
	border-radius: 5px 0 0 5px;
	border: 1px solid $gray-dark;
	border-right: none;
	&.focus {
		border-color: #0079ad;
	}
	& svg {
		color: #707070;
		font-size: 20px;
	}
}
.search-button {
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-top: 0;
	border-bottom: 0;
	line-height: 1;
}

.search-button:hover {
	cursor: pointer;
}
</style>

<i18n>
{
  "en": {
    "searchLabel": "Search",
    "searchPlaceholder": "Search by keyword"
  },
  "fr": {
    "searchLabel": "Rechercher",
    "searchPlaceholder": "Recherche par mot-clé"
  }
}
</i18n>
