<!-- Copied from BaseAccordionPanel to facilitate layout for EHB. -->
<template>
	<div class="ehb-accordion-panel" :class="{ visible: isVisible }">
		<BButton
			:aria-expanded="isVisible ? 'true' : 'false'"
			:aria-controls="id"
			class="ehb-accordion-btn"
			:class="{ collapsed: !isVisible }"
			variant="link"
			block
			:automation-id="getAutomationId('accordionButton')"
			@click="changeVisibility(!isVisible, true)"
		>
			<div class="d-flex">
				<span class="ehb-accordion-btn-icon-container">
					<font-awesome-icon
						:icon="['fal', 'angle-down']"
						size="lg"
						class="ehb-accordion-btn-icon"
						:class="{ flip: iconDirection }"
						aria-hidden="true"
					/>
				</span>
				<span>
					<!-- eslint-disable-next-line -->
					<span v-if="htmlLabel" class="ehb-accordion-btn-label" v-html="buttonLabel" />
					<span v-else class="ehb-accordion-btn-label">{{ buttonLabel }}</span>
					<p v-if="buttonSubLabel" class="ehb-accordion-btn-sub-label">
						{{ buttonSubLabel }}
					</p>
				</span>
			</div>
		</BButton>
		<BCollapse
			:id="id"
			v-bind="$attrs"
			class="ehb-accordion-content"
			:accordion="groupName"
			:visible="isVisible"
			:data-test-automation-id="getAutomationId('accordionContent')"
			:data-tracking-id="getAutomationId('accordionContent')"
			@input="changeVisibility"
			@show="$emit('show')"
		>
			<slot></slot>
		</BCollapse>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id.js';
import { BCollapse, BButton } from 'bootstrap-vue';

@Component({
	name: 'EHBAccordionPanel',
	components: {
		BCollapse,
		BButton
	},
	inheritedAttrs: false,
	mixins: [IdMixin],
	props: {
		// From Bootstrap-vue: The name of the accordion group that this collapse belongs to
		groupName: {
			type: String,
			required: true
		},
		// From Bootstrap-vue: When 'true', expands the collapse
		visible: {
			type: Boolean,
			default: null
		},
		// From Bootstrap-vue: 	Used to set the `id` attribute on the rendered content, and used as the base to generate any additional element IDs as needed
		id: {
			type: String,
			default: ''
		},
		htmlLabel: {
			type: Boolean,
			default: false
		},
		buttonLabel: {
			type: String,
			default: ''
		},
		buttonSubLabel: {
			type: String,
			default: ''
		}
	},
	watch: {
		visible: function (newVisible) {
			// watch it
			this.isVisible = newVisible;
		}
	}
})
export default class BaseAccordionPanel extends Vue {
	// data
	isVisible = false;

	// computed
	get iconDirection() {
		return this.isVisible ? 'vertical' : null;
	}

	// methods
	changeVisibility(visible, isClick = false) {
		if (isClick) this.$emit('click');
		if (this.visible !== null) {
			this.$emit('input', visible);
		}
		this.isVisible = visible;
	}

	created() {
		this.isVisible = this.visible;
	}
}
</script>

<style lang="scss" scoped>
// Change background color on hover.  Only for closed accordion pannels.
.ehb-accordion-panel {
	display: contents;
	&:hover:not(.visible) {
		background-color: $gray-very-light;
	}
}
.ehb-accordion-btn {
	text-align: left;
	box-shadow: none;
	text-decoration: none;
	margin: 0;
	padding: 25px 15px;
	&-icon-container {
		max-width: 25px;
	}
	&-icon {
		margin: 4px 15px 0 0;
		transform: scaleY(1);
		transition: transform 350ms ease;
		&.flip {
			transform: scaleY(-1);
		}
	}
	&-label {
		font-size: 21px;
		letter-spacing: -0.06px;
		word-break: break-word;
	}
	&-sub-label {
		font-family: $font-family-regular;
		color: $black;
		font-size: 17px;
		line-height: 24px;
		font-weight: 300;
		margin-bottom: 5px;
	}
}
.ehb-accordion-btn:hover,
.ehb-accordion-btn:focus-visible {
	cursor: pointer;
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-underline-offset: 2px;
}
</style>
