<template>
	<BCard no-body class="ehb-accordion">
		<slot></slot>
	</BCard>
</template>

<script>
import { BCard } from 'bootstrap-vue';

export default {
	name: 'EHBAccordion',
	components: {
		BCard
	}
};
</script>

<style lang="scss" scoped>
.ehb-accordion {
	border: 1px solid $gray;
	background-color: $white;
	margin-bottom: 15px;

	& > ::v-deep .card-body {
		padding: 0;
	}
}
</style>
