var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.benefits),function(benefit,index){return _c(_setup.EHBAccordion,{key:`${benefit.id}${_setup.parentIndex}`,staticClass:"parent-accordion mt-3",attrs:{"aria-live":"polite"}},[(benefit.subcategories.length === 0)?_c(_setup.BaseButton,{staticClass:"accordion-panel-button",attrs:{"automation-id":`benefit-${index}`,"variant":"link","html-label":"","label":_setup.highlight(
					benefit.info ? benefit : benefit.category.description,
					benefit.category.description
				)},on:{"click":function($event){return _setup.serviceClick(benefit.category.description, null, benefit.category.services[0])}}}):_c(_setup.EHBAccordionPanel,{ref:"benefitPanel",refInFor:true,attrs:{"id":index.toString(),"visible":_setup.parentIndex === index,"automation-id":`benefit-${index}`,"group-name":"categories","html-label":"","button-label":_setup.highlight(
					benefit.info ? benefit : benefit.category.description,
					benefit.category.description
				)},on:{"click":function($event){return _setup.closePanels()}}},[_vm._l((benefit.subcategories),function(childBenefit,cIndex){return [(_setup.showChildButton(childBenefit))?_c(_setup.BaseButton,{key:`btn-${index}${cIndex}${_setup.childIndex ? _setup.childIndex : ''}`,staticClass:"child-accordion-button",attrs:{"automation-id":`benefit-${index}`,"variant":"link","html-label":"","label":_setup.highlight(
							childBenefit.info ? childBenefit : childBenefit.description,
							childBenefit.description
						)},on:{"click":function($event){return _setup.serviceClick(
							benefit.category.description,
							childBenefit.description,
							childBenefit.services[0]
						)}}}):_c(_setup.EHBAccordion,{key:`acc-${index}${cIndex}${_setup.childIndex ? _setup.childIndex : ''}`,staticClass:"child-accordion"},[_c(_setup.EHBAccordionPanel,{ref:"childBenefitPanel",refInFor:true,staticClass:"child-accordion-panel",attrs:{"id":`${index}${cIndex}`,"visible":_setup.childIndex === `${index}${cIndex}`,"automation-id":`benefit-${index}${cIndex}`,"group-name":"subCategories","html-label":"","button-label":_setup.highlight(
								childBenefit.info ? childBenefit : childBenefit.description,
								childBenefit.description
							)}},[_c('div',{staticClass:"child-accordion-panel-content mb-4"},[_c('h3',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t('benefitIncludes')))]),_vm._l((childBenefit.services),function(service,grandChildIndex){return _c(_setup.BaseButton,{key:`service.code-${cIndex}${grandChildIndex}`,staticClass:"service-link mb-2",attrs:{"automation-id":`service-${index}${cIndex}${grandChildIndex}`,"variant":"link","html-label":"","pill":false,"label":_setup.highlight(
										service.info ? service : service.shortDescription,
										service.shortDescription
									)},on:{"click":function($event){return _setup.serviceClick(benefit.category.description, childBenefit.description, service)}}})})],2)])],1)]})],2)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }