<script setup>
import { onMounted, ref, computed, watch, defineProps, defineEmits } from 'vue';
// components
import BaseButton from '@/components/common/base/BaseButton.vue';
import EHBAccordion from '@/components/coverage/extended-health/EHBAccordion.vue';
import EHBAccordionPanel from '@/components/coverage/extended-health/EHBAccordionPanel.vue';

const props = defineProps({
	benefits: {
		type: Array,
		required: true
	},
	nonFilteredBenefits: {
		type: Array,
		required: true
	},
	accordionsOpen: {
		type: Array,
		default: () => [null, null]
	}
});
const emit = defineEmits(['serviceClick']);

watch(
	() => props.accordionsOpen,
	(newValue) => {
		accordionsOpenValues.value = newValue;
	}
);

// data
const accordionsOpenValues = ref([null, null]);
const benefitPanel = ref(null);
const childBenefitPanel = ref(null);

// computed
const parentIndex = computed(() => accordionsOpenValues.value[0]);
const childIndex = computed(() => accordionsOpenValues.value[1]);

// methods
function highlight(item, displayValue) {
	if (typeof item === 'string') return item;

	const indices = item.info?.indices;
	return indices
		? indices
				.reduce((str, [start, end]) => {
					str[start] = `<mark>${str[start]}`;
					str[end] = `${str[end]}</mark>`;
					return str;
				}, displayValue.split(''))
				.join('')
		: displayValue;
}
function serviceClick(benefit, subCategory, service) {
	accordionsOpenValues.value[0] = parseInt(
		benefitPanel.value.find((accordion) => {
			return accordion.isVisible === true;
		})?.id
	);
	accordionsOpenValues.value[1] = childBenefitPanel.value.find(
		(accordion) => accordion.isVisible === true
	)?.id;
	emit('serviceClick', {
		benefit,
		subCategory,
		service,
		accordionsOpen: accordionsOpenValues.value
	});
}
function closePanels() {
	childBenefitPanel.value.forEach((panel) => {
		if (panel.isVisible) panel.isVisible = false;
	});
}
function showChildButton(childBenefit) {
	return (
		childBenefit.services.length === 1 &&
		childBenefit.description.toLowerCase() ===
			childBenefit.services[0].shortDescription.toLowerCase()
	);
}

// lifecycle hooks
onMounted(() => {
	accordionsOpenValues.value = props.accordionsOpen;
});
</script>

<template>
	<div>
		<EHBAccordion
			v-for="(benefit, index) in benefits"
			:key="`${benefit.id}${parentIndex}`"
			class="parent-accordion mt-3"
			aria-live="polite"
		>
			<BaseButton
				v-if="benefit.subcategories.length === 0"
				:automation-id="`benefit-${index}`"
				variant="link"
				class="accordion-panel-button"
				html-label
				:label="
					highlight(
						benefit.info ? benefit : benefit.category.description,
						benefit.category.description
					)
				"
				@click="serviceClick(benefit.category.description, null, benefit.category.services[0])"
			/>
			<EHBAccordionPanel
				v-else
				:id="index.toString()"
				ref="benefitPanel"
				:visible="parentIndex === index"
				:automation-id="`benefit-${index}`"
				group-name="categories"
				html-label
				:button-label="
					highlight(
						benefit.info ? benefit : benefit.category.description,
						benefit.category.description
					)
				"
				@click="closePanels()"
			>
				<template v-for="(childBenefit, cIndex) in benefit.subcategories">
					<BaseButton
						v-if="showChildButton(childBenefit)"
						:key="`btn-${index}${cIndex}${childIndex ? childIndex : ''}`"
						:automation-id="`benefit-${index}`"
						variant="link"
						class="child-accordion-button"
						html-label
						:label="
							highlight(
								childBenefit.info ? childBenefit : childBenefit.description,
								childBenefit.description
							)
						"
						@click="
							serviceClick(
								benefit.category.description,
								childBenefit.description,
								childBenefit.services[0]
							)
						"
					/>
					<EHBAccordion
						v-else
						:key="`acc-${index}${cIndex}${childIndex ? childIndex : ''}`"
						class="child-accordion"
					>
						<EHBAccordionPanel
							:id="`${index}${cIndex}`"
							ref="childBenefitPanel"
							:visible="childIndex === `${index}${cIndex}`"
							:automation-id="`benefit-${index}${cIndex}`"
							class="child-accordion-panel"
							group-name="subCategories"
							html-label
							:button-label="
								highlight(
									childBenefit.info ? childBenefit : childBenefit.description,
									childBenefit.description
								)
							"
						>
							<div class="child-accordion-panel-content mb-4">
								<h3 class="mt-2">{{ $t('benefitIncludes') }}</h3>
								<BaseButton
									v-for="(service, grandChildIndex) in childBenefit.services"
									:key="`service.code-${cIndex}${grandChildIndex}`"
									:automation-id="`service-${index}${cIndex}${grandChildIndex}`"
									variant="link"
									class="service-link mb-2"
									html-label
									:pill="false"
									:label="
										highlight(
											service.info ? service : service.shortDescription,
											service.shortDescription
										)
									"
									@click="
										serviceClick(benefit.category.description, childBenefit.description, service)
									"
								/>
							</div>
						</EHBAccordionPanel>
					</EHBAccordion>
				</template>
			</EHBAccordionPanel>
		</EHBAccordion>
	</div>
</template>

<style lang="scss" scoped>
h3 {
	font-size: 18px;
	line-height: normal;
}
::v-deep mark {
	color: $black;
	background-color: #ffff00;
	padding: 0;
}
.parent-accordion {
	overflow: hidden;
	font-weight: normal;
}
.child-accordion {
	overflow: hidden;
	border-radius: 0;
	border: none;
	margin: 0;
	& ::v-deep .ehb-accordion-btn {
		padding: 15px 15px;
		border-radius: 0;
		border-top: 1px solid $gray;
	}
}
::v-deep .child-accordion-panel {
	& .child-accordion-panel-content {
		margin-left: 25px;
		& .service-link {
			display: block;
			line-height: 1.2em;
			padding: 0;
			font-family: $lato-font-family;
			font-size: 17px;
			text-align: left;
			&:last-of-type {
				margin-bottom: 25px;
			}
			&:focus-visible {
				outline: none;
				text-decoration: underline;
			}
		}
	}
	& > .ehb-accordion-content {
		padding: 0 15px;
	}
	& .ehb-accordion-btn-label {
		font-size: 16px;
	}
}
.accordion-panel-button {
	text-align: left;
	padding: 25px 15px 25px 40px;
	font-size: 21px;
}
.child-accordion-button {
	display: block;
	padding: 15px 15px 15px 40px;
	border-top: 1px solid $gray;
	border-radius: 0 !important;
	width: 100%;
	text-align: left;
}
</style>

<i18n lang="json">
{
	"en": {
		"benefitIncludes": "This benefit includes:"
	},
	"fr": {
		"benefitIncludes": "Cette garantie comprend :"
	}
}
</i18n>
