<template>
	<BaseAlert
		class="mb-4"
		:show="show"
		variant="warning"
		alternate
		:automation-id="getAutomationId('medical-exclusion-alert')"
	>
		<p class="m-0 mb-2 title">{{ $t('title') }}</p>
		<BaseButton
			automation-id="medical-exclusion-link"
			:pill="false"
			variant="link"
			type="button"
			class="p-0"
			:label="$t('link')"
			:icon="['fal', 'angle-right']"
			icon-position="right"
			@click="openMedicalExclusionPage"
		/>
	</BaseAlert>
</template>

<script>
// mixins
import IdMixin from '@/mixins/id';
// components
import BaseAlert from '@/components/common/alert/BaseAlert';
import BaseButton from '@/components/common/base/BaseButton';
// routes
import { COVERAGE } from '@/constants/Routes.js';

export default {
	name: 'MedicalExclusionAlert',
	components: {
		BaseAlert,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		show: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		openMedicalExclusionPage() {
			this.$router.push({ path: COVERAGE.MEDICAL_EXCLUSION });
		}
	}
};
</script>

<style lang="scss" scoped>
.title {
	font-family: $lato-font-family;
	font-size: 17px;
	font-weight: bold;
}
.link {
	color: $blue;
}
</style>

<i18n>
{
    "en": {
        "title": "Some Medical Exclusions Apply",
        "link": "View the people on your plan with medical exclusions"
    },
    "fr": {
        "title": "Certaines exclusions médicales s'appliquent",
        "link": "Voir les exclusions médicales des personnes couvertes par votre régime"
    }
}
</i18n>
