<script>
import { BRow, BCol } from 'bootstrap-vue';
import { format } from 'date-fns';
import { enCA, frCA } from 'date-fns/esm/locale';
import Fuse from 'fuse.js';
// mixins
import id from '@/mixins/id';
// models
import Dependent from '@/models/Dependent.js';
// components
import SearchInput from '@/components/common/SearchInput.vue';
import CoverageAccordion from './CoverageAccordion.vue';
import ParticipantDropdown from '@/components/common/ParticipantDropdown.vue';
import MedicalExclusionAlert from '@/components/common/alert/MedicalExclusionAlert';

export default {
	name: 'CoverageParticipantsSearch',
	components: {
		BRow,
		BCol,
		SearchInput,
		CoverageAccordion,
		MedicalExclusionAlert,
		ParticipantDropdown
	},
	mixins: [id],
	props: {
		page: {
			type: String,
			required: true
		},
		model: {
			type: Function,
			default: () => {}
		},
		detailsRoute: {
			type: String,
			required: true
		},
		clearState: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			searchKey: this.$store.state.coverage[this.page].searchKey,
			benefits: [],
			selectedParticipant: this.$store.state.coverage[this.page].selectedParticipant,
			selectedParticipantIndex: this.$store.state.coverage[this.page].selectedParticipantIndex,
			participants: null,
			filteredBenefits: this.$store.state.coverage[this.page].filteredBenefits,
			accordionsOpen: this.$store.state.coverage[this.page].accordionsOpen,
			noCoverage: false,
			loaded: false,
			fuseOptions: {
				includeMatches: true,
				findAllMatches: true,
				isCaseSensitive: false,
				shouldSort: false,
				ignoreLocation: true,
				includeScore: true,
				minMatchCharLength: 2,
				threshold: 0.2, // Lowering this requires member to be more precise in their typing. (default: 0.6)
				keys: [
					'category.nDescription',
					'category.services.tags',
					'subcategories.nDescription',
					'subcategories.services.nShortDescription',
					'subcategories.services.tags'
				]
			}
		};
	},
	computed: {
		computedBenefitsHeading() {
			return this.locale === 'fr'
				? `${this.$t('benefitsHeading')} ${this.selectedParticipant.firstName}`
				: `${this.selectedParticipant.firstName}'s ${this.$t('benefitsHeading')}`;
		},
		locale() {
			return this.$root.$i18n.locale;
		}
	},
	watch: {
		locale: async function () {
			const benefitsExist = await this.model.hasBenefits(
				this.selectedParticipant?.participantId,
				this.locale
			);

			if (!benefitsExist) this.$store.dispatch('updateLoading', true);

			// clear data when locale changes
			this.searchKey = '';
			this.benefits.data = [];
			this.filteredBenefits = [];
			this.accordionsOpen = [null, null];

			await this.getBenefits();

			this.$store.dispatch('updateLoading', false);
		}
	},
	async created() {
		const email = sessionStorage.getItem('email');
		const participantsExist = await Dependent.hasDependents(email);
		const benefitsExist = await this.model.hasBenefits(
			this.selectedParticipant?.participantId,
			this.locale
		);

		if (this.clearState) this.resetState();
		if (!participantsExist || !benefitsExist) this.$store.dispatch('updateLoading', true);

		sessionStorage.setItem(`${this.page}DetailsInfo`, null);

		this.participants = await Dependent.getDependents(
			email,
			sessionStorage.getItem('apiToken'),
			this.locale,
			true
		);
		this.selectedParticipant = this.participants.data[this.selectedParticipantIndex];

		await this.getBenefits();

		this.accordionsOpen = this.$store.state.coverage[this.page].accordionsOpen;

		this.$store.dispatch(`${this.page}Update`, this.$data);
		this.$store.dispatch('updateLoading', false);
		this.loaded = true;
	},
	methods: {
		resetState() {
			this.searchKey = '';
			this.selectedParticipant = null;
			this.selectedParticipantIndex = 0;
			this.filteredBenefits = [];
			this.accordionsOpen = [null, null];
			this.$store.dispatch(`${this.page}Update`, this.$data);
		},
		getFullName(participant) {
			return `${participant.firstName} ${participant.lastName}`;
		},
		async setActiveParticipant(data) {
			const benefitsExist = await this.model.hasBenefits(
				data.participant.participantId,
				this.locale
			);

			if (!benefitsExist) this.$store.dispatch('updateLoading', true);

			this.selectedParticipant = data.participant;
			this.searchKey = '';
			this.filteredBenefits = [];
			this.selectedParticipantIndex = data.index;
			this.accordionsOpen = [null, null];

			await this.getBenefits();

			if (this.$store.state.loading) this.$store.dispatch('updateLoading', false);
		},
		formatDob(dob) {
			return format(
				new Date(dob.substring(0, 4), dob.substring(4, 6) - 1, dob.substring(6, 8)),
				'dd MMM yyyy',
				{ locale: this.locale === 'fr' ? frCA : enCA }
			);
		},
		sortFilteredBenefits(level, data) {
			const sorted = [...data];
			return sorted.sort((a, b) => {
				const aScore = a.info?.refIndex;
				const bScore = b.info?.refIndex;

				if (aScore !== undefined && bScore !== undefined) {
					return aScore - bScore;
				} else if (aScore !== undefined) {
					return -1;
				} else if (bScore !== undefined) {
					return 1;
				}

				let aCategoryDesc;
				let bCategoryDesc;

				if (level === 'benefit') {
					aCategoryDesc = a.category?.description || '';
					bCategoryDesc = b.category?.description || '';
				} else if (level === 'subcategory') {
					aCategoryDesc = a.description || '';
					bCategoryDesc = b.description || '';
				} else if (level === 'service') {
					aCategoryDesc = a.shortDescription || '';
					bCategoryDesc = b.shortDescription || '';
				}

				return aCategoryDesc.localeCompare(bCategoryDesc, undefined, { sensitivity: 'base' });
			});
		},
		normalize(str) {
			return str
				?.normalize('NFD')
				.replace(/[\u0300-\u036F]/g, '')
				.replace(/[,&()-]/g, ' ');
		},
		filterBenefits(unfiltered) {
			const benefits = unfiltered.map((benefit) => {
				// add category description to obj
				let obj = {
					category: {
						description: benefit.item.category.description
					},
					id: benefit.item.id
				};

				// add matches to array for comparison
				const matchesFlat = benefit.matches.map((m) => m.value);

				// if match is at category level
				let hasTagMatch = false;
				if ('services' in benefit.item.category) {
					benefit.item.category.services.forEach((ser) => {
						// match only tags because category will be the same as service description
						if (ser.tags?.some((tag) => matchesFlat.includes(tag))) {
							hasTagMatch = true;
						}
					});
				}
				if (matchesFlat.includes(benefit.item.category.nDescription) || hasTagMatch) {
					obj = { ...benefit.item };
					if (!hasTagMatch) {
						obj.info = benefit.matches.find((m) => m.key === 'category.nDescription');
						obj.info.refIndex = 0;
					}
					obj.hasMatch = true;
				}
				const subcategories = [];
				benefit.item.subcategories.forEach((sub) => {
					const services = [];
					const newSub = { ...sub };
					// if subcategory description matches
					if (matchesFlat.includes(sub.nDescription)) {
						newSub.info = benefit.matches.find((m) => m.value === sub.nDescription);
						// add all services
						newSub.services = sub.services.map((ser) => {
							const newSer = { ...ser };
							delete newSer.info;
							if (matchesFlat.includes(newSer.nShortDescription)) {
								// only add info (for highlighting) to services that have a match
								newSer.info = benefit.matches.find((m) => m.value === newSer.nShortDescription);
							}
							return newSer;
						});
						subcategories.push({
							description: newSub.description,
							nDescription: newSub.nDescription,
							services: newSub.services,
							info: newSub.info
						});
					} else {
						// subcategory doesn't have a match
						delete newSub.info;
						newSub.services.forEach((ser) => {
							const newSer = { ...ser };
							delete newSer.info;
							if (
								matchesFlat.includes(newSer.nShortDescription) ||
								newSer.tags?.some((tag) => matchesFlat.includes(tag))
							) {
								// only add service if there is a match
								newSer.info = benefit.matches.find((m) => m.value === newSer.nShortDescription);
								services.push(newSer);
							}
						});
						if (services.length > 0) {
							subcategories.push({
								description: newSub.description,
								services
							});
						}
					}
					sub.services = this.sortFilteredBenefits('service', sub.services);
				});
				// if category description matches, add all subcategories/services
				obj.subcategories = this.sortFilteredBenefits(
					'subcategory',
					obj.hasMatch ? benefit.item.subcategories : subcategories
				);
				return obj;
			});
			return this.sortFilteredBenefits('benefit', benefits);
		},
		async searchInput() {
			let searchString = '';
			if (this.searchKey.length <= 1) {
				this.benefits.data = this.benefits.data.map((b) => {
					b.subcategories = this.sortFilteredBenefits(
						'subcategory',
						b.subcategories.map((s) => {
							s.services = this.sortFilteredBenefits(
								'service',
								s.services.map((ser) => {
									return ser;
								})
							);
							return s;
						})
					);
					return b;
				});
				// close panels
				this.accordionsOpen = [null, null];
				this.filteredBenefits = this.sortFilteredBenefits('benefit', this.benefits.data);
			} else if (this.searchKey.length > 1) {
				// this variable strips accents from search string
				searchString = this.normalize(this.searchKey);

				this.fuseOptions.minMatchCharLength = Math.max(searchString.length - 2, 2);

				let normalizedBenefits = [...this.benefits.data];
				normalizedBenefits = normalizedBenefits.map((ben) => {
					ben.category.nDescription = this.normalize(ben.category.description);
					ben.nSubcategories = [
						...ben.subcategories.map((sub) => {
							sub.nDescription = this.normalize(sub.description);
							sub.nServices = [
								...sub.services.map((ser) => {
									ser.nLongDescription = this.normalize(ser.longDescription);
									ser.nShortDescription = this.normalize(ser.shortDescription);
									return ser;
								})
							];
							return sub;
						})
					];
					return ben;
				});

				const fuse = new Fuse(normalizedBenefits, this.fuseOptions);

				this.filteredBenefits = this.filterBenefits(fuse.search(searchString));

				const childIndex = this.filteredBenefits[0]?.subcategories.length === 1 ? '00' : null;

				if (this.filteredBenefits.length > 0) this.accordionsOpen = [0, childIndex];
			}
		},
		accordionServiceClick(data) {
			this.accordionsOpen = data.accordionsOpen;
			this.$store.dispatch(`${this.page}Update`, this.$data);

			this.$router.push({
				name: this.detailsRoute,
				query: { code: data.service.code, participant: this.selectedParticipant.participantId }
			});
		},
		async getBenefits() {
			const pId = this.selectedParticipant.participantId;
			this.benefits = await this.model.getBenefits(
				sessionStorage.getItem('email'),
				pId,
				sessionStorage.getItem('apiToken'),
				this.locale
			);
			this.noCoverage = this.benefits.data.length === 0;
			this.searchInput();
		}
	}
};
</script>

<template>
	<div v-if="!$store.state.loading && loaded">
		<BRow v-if="selectedParticipant">
			<BCol cols="12" lg="10">
				<MedicalExclusionAlert :show="selectedParticipant.medicalExclusion" />
			</BCol>
		</BRow>
		<BRow v-if="participants.data.length > 1">
			<BCol>
				<h2 class="h2-coverage">{{ $t('coverageHeading') }}</h2>
				<p>{{ $t('coverageDescription') }}</p>
				<ParticipantDropdown
					:selected-participant="selectedParticipant"
					:participants="participants.data"
					@click="setActiveParticipant"
				/>
				<hr class="my-4" />
			</BCol>
		</BRow>
		<template v-if="benefits.data.length > 0">
			<BRow>
				<BCol>
					<h2 class="h2-benefit">{{ computedBenefitsHeading }}</h2>
				</BCol>
			</BRow>
			<BRow>
				<BCol cols="12" lg="10">
					<SearchInput
						v-model="searchKey"
						class="search-autocomplete mb-4"
						search-prepend
						:search-button="false"
						automation-id="search-input"
						@input="searchInput"
					/>
				</BCol>
			</BRow>
		</template>
		<BRow>
			<BCol cols="12" :lg="noCoverage ? '12' : '10'">
				<template v-if="noCoverage">
					<h2 class="h2-nocoverage">
						{{ $t('noCoverageHeading') }}
					</h2>
					<p class="no-coverage-description">
						{{ $t('noCoverageDescription') }}
					</p>
				</template>
				<template v-else-if="filteredBenefits.length === 0 && searchKey.length > 0">
					<h2 class="h2-no-results">
						{{ $t('notFound.heading') }}
					</h2>
					<p>
						{{ $t('notFound.text') }}
					</p>
				</template>
				<CoverageAccordion
					:benefits="filteredBenefits"
					:non-filtered-benefits="benefits.data"
					:accordions-open="accordionsOpen"
					@serviceClick="accordionServiceClick"
				/>
			</BCol>
		</BRow>
	</div>
</template>

<style lang="scss" scoped>
// needed to overwrite global theme
h2,
p {
	line-height: normal;
}
.h2-coverage,
.h2-no-results {
	font-size: 18px;
}
.h2-nocoverage {
	font-size: 21px;
}
.h2-benefit {
	font-size: 24px;
}
hr {
	border-top: 1px solid #bfbebf;
}
.no-coverage-description {
	font-weight: 300;
	font-size: 21px;
}
.search-autocomplete {
	max-width: 50%;
	@include media-breakpoint-down(sm) {
		max-width: 100%;
	}
}
</style>

<i18n lang="json">
{
	"en": {
		"coverageHeading": "Who is the coverage for?",
		"coverageDescription": "Each participant on your plan may have different coverage. Select a participant to see coverage details for that person.",
		"noCoverageHeading": "Coverage not applicable",
		"noCoverageDescription": "This benefit is not available for the plan member you have selected. Please choose another plan member.",
		"benefitsHeading": "Benefits",
		"notFound": {
			"heading": "No results found",
			"text": "Please try another search."
		}
	},
	"fr": {
		"coverageHeading": "Qui a besoin de la couverture?",
		"coverageDescription": "Chaque personne assurée par votre régime pourrait avoir une couverture différente. Sélectionnez une personne assurée pour voir les détails de sa couverture.",
		"noCoverageHeading": "La couverture ne s'applique pas",
		"noCoverageDescription": "La couverture ne s'applique pas à l'adhérent(e) sélectionné(e). Veuillez choisir un(e) autre adhérent(e).",
		"benefitsHeading": "Garanties de",
		"notFound": {
			"heading": "Aucun résultat ne correspond à votre recherche.",
			"text": "Veuillez réessayer."
		}
	}
}
</i18n>
